import React from 'react'

const footer = () => (
  <div>
   
 
  <section className="footer">
  <div className="blanco d-flex justify-content-center">
  <p>FGZone Copyright® 2022  - Diseñado en Reactjs </p>
  <p></p>
  </div>
  
 


  </section>
  
   </div>
)

export default footer