import React from 'react';
import emailjs from '@emailjs/browser';

import {Row,Col, Container} from 'react-bootstrap'
import Typical from 'react-typical'
import Fade from 'react-reveal/Fade';
export default function ContactUs() {

    function sendEmail(e) {
        e.preventDefault();
        const name = document.getElementsByName('name')[0].value;
        const email = document.getElementsByName('email')[0].value;
        const subject = document.getElementsByName('subject')[0].value;
        const message = document.getElementsByName('message')[0].value;
        
        if (name!=="" & email!=="" & subject!=="" & message!=="")
        {
            emailjs.sendForm('service_y5m858t', 'template_616rfvk', e.target, 'user_rYorhYeuxRSg0pYyWe02E')
        .then((result) => {
            alert("El formulario fue enviado con exito");
            e.target.reset()
        }, (error) => {
            window.alert(error.text);
        });
        
        }
        else {
            alert("Complete los campos del formulario")
        }
  
    }

    return(
        <section className="form">
            <div className="titulo d-flex justify-content-center ">
  <Typical
                  steps={["Mantente en contacto", 3000,]}
                  loop={Infinity}
                  wrapper="p"
                />
    
    </div>
  <Container fluid>
  <div className="flex-column d-flex justify-content-center align-items-center itemes">
        <Fade right>
      
      <a href="mailto:info@fgzone.com.ar">info@fgzone.com.ar</a>
      </Fade>
      </div>
  
    <div className="itemes redes">
        <Fade right>
        <Row>
       <Col>
        <a href="https://www.youtube.com/channel/UC2aRict4s9Jwt8DAOWjBA2Q"target="_blank"><i className="fa fa-youtube-square rojo"></i></a>
        <p>Tutoriales (Proximamente)</p>
        </Col>
       <Col>
        <a href="https://github.com/FGZone1"target="_blank"><i className="fa fa-github-square negro"></i></a>
        <p>Proyectos (Proximamente)</p>
        </Col>
        </Row> 
</Fade>
</div>


   
  
    <Fade left>
            <div className="container">
            <form onSubmit={sendEmail}>
                    <div className="row pt-5 mx-auto">
                        <div className="col-8 form-group mx-auto">
                            <input type="text" className="form-control" placeholder="Nombre"  name="name" Required/>
                        </div>
                        <div className="col-8 form-group pt-1 mx-auto">
                            <input type="email" className="form-control" placeholder="Email" name="email" Required/>
                        </div>
                        <div className="col-8 form-group pt-1 mx-auto">
                            <input type="text" className="form-control" placeholder="Asunto" name="subject"/>
                        </div>
                        <div className="col-8 form-group pt-1 mx-auto">
                            <textarea className="form-control" id="" cols="30" rows="8" placeholder="Tu mensaje" name="message"></textarea>
                        </div>
                        <div className="col-8 pt-1 mx-auto">
                            <input type="submit" className="btn btn-info" value="Enviar"></input>
                           
                        </div>
                    </div>
                </form>
            </div>
            </Fade>
           
         
            </Container>
            
        </section>
    )
}