import React from 'react'
import pag from "../img/pag.png"
import Fade from 'react-reveal/Fade';
import {Container,Row,Col} from 'react-bootstrap';
import Typical  from "react-typical"
const Servicios = () => (
  <div>
   
 
  <section className="servicios">
  <div className="titulo d-flex justify-content-center ">
  <Typical
                  steps={["¿Qué hacemos?", 3000,"¿Qué ofrecemos?", 5000]}
                  loop={Infinity}
                  wrapper="p"
                />
    {/* <p>¿Que hacemos?</p> */}
    </div>
  <Container fluid>
  
  <Row>
    <Col >
    <div className="vh-100 flex-column d-flex justify-content-center  items">
        <Fade right>
    <p>🔧 SysAdmin</p>
    <p>📱 Software</p>
    <p>..✔️ Frontend</p>
    <p>..✔️ Backend</p>
    <p>..✔️ Web</p>
    <p>..✔️ Desktop</p>
    <p>..✔️ Wordpress</p>
</Fade>
</div>

    </Col>
    <Col >
    
    <Fade left>
    <div className="vh-100 d-flex justify-content-center align-items-center">
    <img className='imagen' src={pag}></img>
    </div>
</Fade> 


</Col>

  </Row>
 

</Container>
  </section>
  
   </div>
)
{/* <Tarjeta Titulo='Web' Texto="Una Web donde las personas puedan conocer su Empresa" Imagen={web}/> */}
export default Servicios