import React from "react";
import Servicios from '../paginas/servicios.js'
import About from '../paginas/informacion.js'
import Barra from '../paginas/navbar.js'
import FormPage from '../paginas/formulario.js'
import Footer from '../paginas/footer.js'
//import '../css/bootstrap.min.css'
import '../css/estilos.css'
const Section = () => {
  return (
    <div>
      <div className="section" id="barra">
      <Barra />
            
    </div>
     
    <div className="section" id="Servicios">
       <Servicios /> 
            
    </div>
    <div className="section" id="lenguajes">
     <About /> 
    </div>
    <div className="section" id="contacto">
    
    
        <FormPage />
    
    
    </div>
   
    <Footer />
    
    </div>
  );
};
export default Section;
