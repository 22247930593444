import React, { useState} from "react";
import {Link} from "react-scroll"
import {Nav, Navbar, Container} from "react-bootstrap";

import Typical  from "react-typical"; //npm i @deadcoder0904/react-typical
import logo from "../img/logofg2.png";


const Barra = () => {
  const [navbar, setNavbar] = useState(false);
  const cambiarcolor = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }

    console.log(window.scrollY);
  };

  window.addEventListener("scroll", cambiarcolor);
  return (
    <div>
      <header class="header">
        {/*  <div className="redes">
            <a href="#">
              <i className="fa fa-facebook-square"></i>
            </a>
            <a href="#">
              <i className="fa fa-instagram"></i>
            </a>
            <a href="#">
              <i className="fa fa-youtube-square"></i>
            </a>
            <a href="#">
              <i className="fa fa-twitter"></i>
            </a>
          </div> */}
  
     
   <div className="vh-100 d-flex flex-column justify-content-center align-items-center">
         <img className="logofg" src={logo}></img>
         <p>   </p>
         
         <div className="letrasmoviles">
        <h1>
                 <Typical
                  steps={["FGZone 👋", 3000,"20 años de experiencia", 4000, "Desarrollos 🦾", 4000 ]}
                  loop={Infinity}
                  wrapper="p"
                />
              
              </h1>
              </div>
             
              </div>
        
        <Container className={navbar ? "cambiomovido" : "cambio"}>
          <Navbar
            bg="primary"
            variant="dark"
            fixed="top"
            collapseOnSelect
            expand="md"
            activeKey="Home"
          >
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Container className="justify-content-center">
              
              {/* <Navbar.Brand href=""></Navbar.Brand> */}
              <div pepe>
                  <Nav className="ml-auto">
                    <Link
                      to="barra"
                      activeClass="active"
                      className="nav-link"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      href=""
                      
                    >
                      Inicio
                    </Link>

                    <Link
                      to="servicios"
                      activeClass="active"
                      className="nav-link"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      href=""
                    >
                      Servicios
                    </Link>
                    <Link
                      to="lenguajes"
                      activeClass="active"
                      className="nav-link"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      href=""
                    >
                      Información
                    </Link>
                    
                    <Link
                      to="contacto"
                      activeClass="active"
                      className="nav-link"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      href=""
                    >
                      Contacto
                    </Link>
                  </Nav>
                  </div>
              </Container>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </header>
      <div></div>
    </div>
  );
};
export default Barra;
