import React from 'react'

import Fade from 'react-reveal/Fade';
import {Container,Row,Col} from 'react-bootstrap';
import Typical from 'react-typical'
import prog from "../img/prog.png"
const About = () => (
  <div>
   
 
  <section className="About">
  <div className="titulo d-flex justify-content-center ">
  <Typical
                  steps={["¿Cómo lo hacemos?", 3000,"¿Qué configuramos o reparamos?", 5000]}
                  loop={Infinity}
                  wrapper="p"
                />
    {/* <p>¿Que hacemos?</p> */}
    </div>
  <Container fluid>
  
  <Row>
    <Col >
    <div className="vh-100 flex-column d-flex justify-content-center  items">
        <Fade right>
    <p>🔧 Desktop</p>
    <p>🔧 Servidores</p>
    <p>📱 HTML5 - CSS</p>
    <p>📱 JavaScript</p>
    <p>📱 .Net</p>
    <p>📱 Reactjs</p>
    <p>📱 Nodejs</p>
    <p>✔️ y mas ...</p>
</Fade>
</div>

    </Col>
    <Col >
    
    <Fade left>
    <div className="vh-100 d-flex justify-content-center align-items-center">
    <img className='imagen'src={prog} ></img>
    </div>
</Fade> 


</Col>

  </Row>
 

</Container>
  </section>
  
   </div>
)

export default About